<template>
    <div class="w100">
      <Toolbar/> 
      <Hero />
      <ContactForm />
      <Footer />
    </div>
  </template>
  
  <script>
  import Toolbar from "@/components/Toolbars/HomePageMenu.vue";
  import Hero from "@/components/Home/ContactHero.vue";
  import ContactForm from "@/components/Home/SupportForm.vue";
  import Footer from "@/components/Home/Footer.vue";
  import Compliant from "@/components/Home/Compliant.vue";
  import Plans from "@/components/Home/Plans.vue";
  import Description from "@/components/Home/Description.vue";
  import InfoBanner from "@/components/Home/InfoBanner.vue";
  
  export default {
    name: "Home",
    components: {
      Hero,
      InfoBanner,
      Toolbar,
      Description,
      Plans,
      Compliant,
      Footer,
      ContactForm,
    },
  };
  </script>
  
  
<template>
    <div class="contact_form center">
        <div class="main_container d-flex wrap center">
            <form @submit.prevent="post" class="grow form">
                <div class="font-40 mb-40">{{ $t("contact.support") }}</div>
                <div class="green--text weight-600 mb-10" v-if="successMessage">
                    {{ successMessage }}
                </div>
                <label for="name">{{ $t("contact.name") }}*</label>
                <input class="input" v-model="name" autofocus :class="{ error: nameError }" />
                <div class="red--text font-14 mb-10" v-if="nameError">
                    {{ nameError }}
                </div>
                <label for="email">{{ $t("contact.email") }}*</label>
                <input class="input" v-model="email" :class="{ error: emailError }" />
                <div class="red--text font-14 mb-10" v-if="emailError">
                    {{ emailError }}
                </div>
                <label for="message">{{ $t("contact.messageSupport") }}*</label>
                <textarea :placeholder="placeholder" type="area" class="input" v-model="message" :class="{ error: messageError }" />
                <div class="red--text font-14" v-if="messageError">
                    {{ messageError }}
                </div>

                <label for="message">{{ $t("contact.uploadSupporting") }}</label>
                <input type="file" class="pt-10" @change="handleFileUpload">


                <s-btn height="50" width="160" class="green mt-30">{{
                    $t("contact.submit")
                }}</s-btn>
            </form>

            <div class="box center d-col grow">
                <img class="logo py-10" src="/img/static/logo-landing.svg" alt="securMeet" />
                <div class="w100">
                    <span class="grey--text">{{ $t("contact.company_title") }}</span>
                    <span>{{ $t("contact.company_value") }}</span>
                </div>
                <div class="w100">
                    <span class="grey--text">{{ $t("contact.address_title") }}</span>
                    <span class="">
                        {{ $t("contact.address_value") }}
                    </span>
                </div>
                <div class="w100">
                    <span class="grey--text"> {{ $t("contact.email_title") }}</span>
                    <a class="green--text" href="mailto:info@securmeet.com">{{
                        $t("contact.email_value")
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { gsap } from "gsap";

export default {
    data() {
        return {
            name: "",
            email: "",
            message: "",
            nameError: "",
            fileBase64: "",
            emailError: "",
            messageError: "",
            successMessage: ""

        };
    },
    methods: {
        async post() {
            if (this.validateForm()) return;
            let data = { name: this.name, email: this.email, message: this.message };
            if (this.fileBase64) data.fileBase64 = this.fileBase64
            await this.$store.dispatch("auth/support", data);
            this.name = "";
            this.email = "";
            this.message = "";
            this.successMessage = this.$t("contact.thanks")
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = (e) => {
                this.fileBase64 = e.target.result.split(',')[1];
            };
        },
        validateForm() {
            let required = "Required";
            let nameError =
                "Only alphanumeric characters, underscore, dot and space allowed, min length 3, max length 63";
            let emailError = "Not valid email address";
            let messageError =
                "Only alphanumeric characters, underscore and dot allowed, min length 3, max length 63";

            let reg = new RegExp("^[a-zA-Z0-9_. ]{3,63}$");
            let name = reg.test(this.name);
            reg = RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            let email = reg.test(this.email);
            reg = new RegExp("^[a-zA-Z0-9_.]{3,63}$");
            let message = true;

            this.nameError = !name ? nameError : "";
            this.emailError = !email ? emailError : "";
            this.messageError = !message ? messageError : "";

            if (this.name.length == 0) this.nameError = required;
            if (this.email.length == 0) this.emailError = required;
            if (this.message.length == 0) this.messageError = required;

            return this.nameError || this.emailError || this.messageError;
        },
    },
    computed: {
        placeholder() {
            return this.$t("contact.placeholder")
        },
    },
    mounted() {
        gsap.from(".form", {
            scrollTrigger: ".form",
            x: 50,
            y: 0,
            opacity: 0,
            duration: 0.75,
            delay: 0.2,
            ease: "power1.out",
        });
        gsap.from(".box", {
            scrollTrigger: ".box",
            x: 0,
            y: 50,
            opacity: 0,
            duration: 0.75,
            delay: 0.3,
            ease: "power1.out",
        });
    },
};
</script>
  
<style scoped>
.input {
    height: 48px;
    margin-bottom: 13px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 3px;
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    margin-top: 10px;
    box-sizing: border-box;
}

.main_container {
    grid-column-gap: 100px;
    grid-row-gap: 50px;
    overflow: hidden;
}

label {
    font-size: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.contact_form {
    background-color: white;
    color: var(--almost-black);
    padding-top: 60px;
    padding-bottom: 60px;
}

.box {
    border: 1px solid rgb(213, 221, 233);
    padding: 30px;
    font-size: 14px;
    line-height: 30px;
    min-height: 200px;
}

form {
    min-width: 200px;
    box-sizing: border-box;
}

.logo {
    max-width: 200px;
}

textarea {
    min-height: 100px;
}

.error {
    background-color: rgb(251, 236, 236);
    border: 1px solid red;
}
</style>
  